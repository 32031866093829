// 
// responsive-table.scss
//

.responsive-table-plugin {
    .dropdown-menu {
        li.checkbox-row {
            padding: 7px 15px;
            color: $dropdown-link-color;
        }
    }

    .table-responsive {
        border: none;
        margin-bottom: 0;
    }

    .btn-toolbar {
        display: block;
    }

    tbody {
        th {
            font-size: 14px;
            font-weight: normal;
        }
    }

    .checkbox-row {
        padding-left: 40px;

        label {
            display: inline-block;
            padding-left: 5px;
            position: relative;
            margin-bottom: 0;

            &::before {
                background-color: transparent;
                border-radius: 3px;
                border: 1px solid $gray-400;
                content: "";
                display: inline-block;
                height: 17px;
                left: 0;
                margin-left: -20px;
                position: absolute;
                transition: 0.3s ease-in-out;
                width: 17px;
                outline: none;
            }

            &::after {
                color: $gray-400;
                display: inline-block;
                font-size: 9px;
                height: 16px;
                left: 0;
                margin-left: -19px;
                padding-left: 3px;
                padding-top: 1px;
                position: absolute;
                top: -2px;
                width: 16px;
            }
        }

        input[type="checkbox"] {
            cursor: pointer;
            opacity: 0;
            z-index: 1;
            outline: none;

            &:disabled+label {
                opacity: 0.65;
            }
        }

        input[type="checkbox"]:focus+label {
            &::before {
                outline-offset: -2px;
                outline: none;
            }
        }

        input[type="checkbox"]:checked+label {
            &::after {
                content: "\f00c";
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
            }
        }

        input[type="checkbox"]:disabled+label {
            &::before {
                background-color: $gray-300;
                cursor: not-allowed;
            }
        }

        input[type="checkbox"]:checked+label {
            &::before {
                background-color: transparent;
                border-color: $primary;
            }

            &::after {
                color: $primary;
            }
        }
    }

    table.focus-on tbody tr.focused th,
    table.focus-on tbody tr.focused td,
    .sticky-table-header {
        background-color: $primary !important;
        --bs-table-accent-bg: $primary !important;
        border-color: $primary !important;
        color: $white;

        table {
            color: $white;
        }
    }

    .fixed-solution {
        .sticky-table-header {
            top: $topbar-height !important;
        }
    }

    .btn-default {
        background-color: $light;
        color: color-yiq($light);
        border: 1px solid rgba($dark, 0.3);

        &.btn-primary {
            background-color: $primary;
            border-color: $primary;
            color: $white;
            box-shadow: 0 0 0 2px rgba($primary, .5);
        }
    }

    .btn-group {
        &.pull-right {
            float: right;

            .dropdown-menu {
                left: auto;
                right: 0;
            }
        }
    }
}

.no-touch {
    .dropdown-menu {
        >.checkbox-row {
            &:hover,
            &:active {
                color: $dropdown-link-active-color;
                background-color: $dropdown-link-active-bg;
            }
        }
    }
}