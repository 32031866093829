// 
// alerts.scss
//

  // alert
  .alert-dismissible {
    .btn-close{
      box-shadow: none;
      background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
    }
}