//
// vector-maps.scss
//

.jvectormap-label {
    border: none;
    background: $color-contrast-dark;
    color: $color-contrast-light;
    font-family: $font-family-secondary;
    font-size: $font-size-base;
    padding: 5px 8px;
}